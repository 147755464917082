import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../../components/layout.css"

// import '../css/index.css'; // add some style if you want!
export default function Index({ data }) {
    const { edges: posts } = data.allMarkdownRemark
    return (
        <div className="container">
        <div className="row">
        <div className="blog-posts">
        <hr />
        <pre>jpaek.com/design_patterns</pre>
        <p>I first came across the "design pattern" term while taking an advanced Java course.  While working on some I18N implementation strategies it covered a few topics, one of them being the Singleton pattern and the first one I learned.  My plan here is to publish on each of the patterns as a "draft" and refine the material over time once I have some content written for each of the patterns as a starting point.</p>
        <hr / >
        </div>
        </div>
        <div className="row">
        <div className="col">
        {posts
            .filter(post => post.node.frontmatter.title.length > 0)
            .map(({ node: post }) => {
                return (
                    <div className="blog-post-preview" key={post.id}>
                    <h5>
                    <Link to={`/design_patterns${post.frontmatter.path}`}>{post.frontmatter.title}</Link>
                    </h5>
                    <p>{post.frontmatter.date}</p>
                    <p>{post.excerpt}</p>
                    </div>
                )
            })}
        </div>
        <div className="col-4">
        <h4 className="typed-heading">23 Design Patterns</h4>
        <p>Design pattern are useful if you can recognize a problem fitting to the ones written by Erich Gamma, Richard Helm, Ralph Johnson, and John Vlissides.  They are commonly known as the "Gang Of Four" ("GoF" in literature).  They popularized a book titled "Design Patterns: Elements of Reusable Object-Oriented Software" in 1994.</p>
        <p>So, here are the 23 design patterns introduced which were organized into the following 3 categories. </p>
        <h4 className="typed-heading">Categories</h4>
        <div className="typed-heading">
        <h5 className="typed-heading">Creational Patterns</h5>
        <ul>
        <li>Abstract Factory</li>
        <li>Builder</li>
        <li>Factory Method</li>
        <li>Prototype</li>
        <li>Singleton</li>
        </ul>

        <h5 className="typed-heading">Structural Patterns</h5>
        <ul>
        <li>Adapater</li>
        <li>Bridge</li>
        <li>Composite</li>
        <li>Decorator</li>
        <li>Facade</li>
        <li>Flyweight</li>
        <li>Proxy</li>
        </ul>
        <h5 className="typed-heading">Behavorial Patterns</h5>
        <ul>
        <li>Chain of Responsibility</li>
        <li>Command</li>
        <li>Interpreter</li>
        <li>Iterator</li>
        <li>Mediator</li>
        <li>Memento</li>
        <li>Observer</li>
        <li>State</li>
        <li>Strategy</li>
        <li>Template Method</li>
        <li>Visitor</li>
        </ul>
        </div>
        <h5 className="typed-heading">Additional Topics</h5>
        <ul> 
        <li>Favor Composition Over Inheritance</li>
        </ul>
        </div>
        </div>
        <hr />
        <div style={{minHeight: '4em', backgroundColor: 'black'}}> &nbsp;</div>
        <hr />
        </div> 
    )
}

// Gatsy will introspect this query "myDesignPatternIndexQuery".  This is an index.
export const pageQuery = graphql`
  query myDesignPatternsIndexQuery {
      allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { fields: { collection: { eq: "design_patterns" } } }) {
              edges {
                  node {
                      excerpt(pruneLength: 250)
                      id
                      frontmatter {
                          title
                          date(formatString: "MMMM DD, YYYY")
                          path
                      }
                  }
              }
          }
  }
`

